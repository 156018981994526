$fontSize: 17px;
$largeFontSize:22px;
$font-family: 'Avenir', 'Arial', sans-serif;
$font-family-bold: 'Avenir Bold', 'Arial Bold', sans-serif;
$font-family-medium: 'Avenir Bold', 'Arial Medium', sans-serif;

* {
  box-sizing: border-box;
  outline: none;
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body,
#root {
  width: 100%;
  height:100%;
  // min-height: 100%;
  margin: auto;
  overflow: auto;
  font-size: $fontSize;
}

body {
  margin: 0;
  font-family: $font-family;
  font-size: $fontSize;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.no-padding {
  padding: 0 !important;
}

.no-wrap {
  white-space: nowrap;
}

.sup {
  vertical-align: super;
  font-size: 1.8vh;
}

.sub {
  vertical-align: sub;
  font-size: 1.8vh;
}

.flex {
  display: flex;
  flex: 1 1 auto;
  position: relative;

  .direction-column {
    flex-direction: column;
  }
}

.flex-center {
  display: flex;
  flex: 1 1 auto;
  position: relative;
  justify-content: center;
  align-items: center;
}

.direction-column {
  flex-direction: column;
}

.flex.wrapper {
  height: 100%;
  width: 100%;
}

.vertical-center {
  align-items: center;
}

.horizontal-center {
  justify-content: center;
}

.game-button{
  width: 95%;
  height: 8vh;
}

// .game-button-selected{
//   background: url('../assets/Branded_Background.png');
//   background: radial-gradient(circle, rgba(8,130,233,1) 93%, rgba(255,255,255,1) 96%) !important;
//   color: #ffffff !important;
// }


.branded {
  background-image: url('../assets/Branded_Background.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  color: #ffffff;
}

.unbranded {
  background-image: url('../assets/Unbranded_Background.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 100%;
  color: #ffffff;
}

.unbranded-button{
  color:#23408F;
  background: rgba(255, 255, 255, 0.8);
  margin: 5px;
  font-size: 3vh;
  font-weight: bold;
  width: 95%;
  height: 8vh;
  min-height: 55px;
  border-radius: 8px;
  border: none;
}
.unbranded-correct-button{
  color: white;
  background: url("../assets/bipolarRight.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.unbranded-incorrect-button{
  color: #ffffff;
  background: url("../assets/bipolarWrong.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.branded-button{
  padding: 15px 12px;
  min-width: 100px;
  border-radius: 16px;
  color: rgb(193, 91, 193);
  background-color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.branded-button-inner-text{
  font-size: 3.4vh;
  font-weight: bold;
}

.branaded-correct-button{
  color: #ffffff;
  background: url("../assets/numberRight.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.branaded-incorrect-button{
  color: #ffffff;
  background: url("../assets/numberWrong.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}